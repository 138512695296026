import { ButtonHTMLAttributes } from "react";
import CircularLoader from "@/Components/CircularLoader";
import "./button.scss";

export default function Button({
  className = "",
  disabled,
  children,
  loading,
  transparent = false,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  transparent?: boolean;
}) {
  return (
    <button
      {...props}
      className={`${transparent ? "" : "btn"} ${className ? className : ""} ${disabled ? "disabled" : ""}`}
      disabled={disabled}
    >
      {children} {loading && <CircularLoader />}
    </button>
  );
}
